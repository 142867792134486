import React from 'react';
import styled from 'styled-components';
import Logo from 'assets/images/logo-full.svg';
import { isTablet } from 'react-device-detect';
import CertificateSection from 'mobile/components/footer/CertificateSection';
import DesignerLogo from 'mobile/components/footer/DesignerLogo';
import { refHeight, refHeightLandscape, refWidth, refWidthLandscape } from '../utils/dimensions';
import GiveoCorpAddress from '../components/commons/GiveoCorpAddress';
import PrivacySection from '../components/commons/PrivacySection';
import FollowUs from '../components/commons/FollowUs';
import { mq } from '../../assets/styles/mediaQuery';
import { useNavigation } from '../utils/useNavigation';

const Container = styled.div`
  margin: auto;
  width: ${isTablet ? '75%' : 'auto'};
  display: grid;
  grid-row-gap: ${refHeight(28)}vh;
  padding: ${refHeight(80)}vh ${refWidth(30)}vw ${refHeight(40)}vh ${refWidth(30)}vw;

  ${mq.landscape} {
    grid-row-gap: ${refHeightLandscape(28)}vh;
    padding: ${refHeight(80)}vh ${refWidth(50)}vw;
  }
`;

const StyledImg = styled.img`
  width: ${refWidth(85)}vw;
  margin-right: ${refWidth(38)}vw;
  height: auto;
  cursor: pointer;

  ${mq.landscape} {
    width: ${refWidthLandscape(85)}vw;
  }
`;

const RowWrapper = styled.div`
  display: flex;
`;

const Footer = () => {
  const { navigate } = useNavigation();

  return (
    <Container>
      <RowWrapper>
        <StyledImg onClick={() => navigate('/')} src={Logo} alt={'Giveo'} />
        <FollowUs />
      </RowWrapper>
      <GiveoCorpAddress />
      <PrivacySection />
      <CertificateSection />
      <DesignerLogo />
    </Container>
  );
};

export default Footer;
