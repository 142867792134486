import styled from 'styled-components';
import { refFontSize, refFontSizeCalc } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { mq } from 'assets/styles/mediaQuery';
import PointerDiv from '../commons/PointerDiv';
import { fonts } from '../../../assets/styles/fonts';
import { TABLET_MULTIPLIER } from '../../../commons/utils/dimensions';

const FONT_SIZE = 14 * TABLET_MULTIPLIER;

export const FooterText = styled.div`
  font-family: ${fonts.family.description};
  color: ${colors.fadedJade};
  font-size: ${refFontSize(FONT_SIZE)}vw;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.4)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.2)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.4)}vw;
  }
`;

export const PointerText = styled(PointerDiv)`
  font-family: ${fonts.family.description};
  color: ${colors.fadedJade};
  font-size: ${refFontSize(FONT_SIZE)}vw;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;

  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.4)}vw;
  }
  ${mq.tablet} {
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.2)}vw;
  }
  ${mq.phone} {
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.4)}vw;
  }
`;

export const FooterSubtext = styled(FooterText)`
  color: ${colors.gullGray};
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
`;
