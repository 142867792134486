import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { refFontSize, refFontSizeLandscape } from '../../utils/dimensions';
import { fonts } from '../../../assets/styles/fonts';
import { mq } from '../../../assets/styles/mediaQuery';

const FooterWrapper = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  color: ${colors.gullGray};
  font-size: ${refFontSize(12)}vw;
  line-height: 2em;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
  }
`;

const GiveoCorpAddress = () => {
  const { t } = useTranslation('common');

  return (
    <FooterWrapper>
      {t('footer-line-1')}
      <br />
      {t('footer-line-2')}
    </FooterWrapper>
  );
};

export default GiveoCorpAddress;
