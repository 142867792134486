import React from 'react';
import styled from 'styled-components';
import { animations } from 'assets/styles/animations';
import { ReactComponent as DesignerIcon } from 'assets/images/heksagraf-logo.svg';
import colors from 'assets/styles/colors';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '../../utils/useNavigation';
import { refWidth, refWidthLandscape } from '../../utils/dimensions';
import { mq } from '../../../assets/styles/mediaQuery';
import { FooterSubtext } from './FooterTypography';

const StyledIcon = styled(DesignerIcon)`
  width: ${refWidth(80)}vw;
  height: 100%;
  margin-left: ${refWidth(10)}vw;
  fill: ${colors.gullGray};
  transition-duration: ${animations.standardDuration};

  ${mq.landscape} {
    width: ${refWidthLandscape(80)}vw;
    margin-left: ${refWidthLandscape(6)}vw;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DesignerLogo = () => {
  const { t } = useTranslation('common');
  const { navigate } = useNavigation();

  return (
    <Wrapper onClick={() => navigate('/')}>
      <FooterSubtext>{t('designed-by')}</FooterSubtext>
      <StyledIcon />
    </Wrapper>
  );
};

export default DesignerLogo;
