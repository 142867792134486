import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PrivacySection from '../commons/PrivacySection';
import { FooterSubtext } from './FooterTypography';
import DesignerLogo from './DesignerLogo';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 5;
  margin-top: 3.25rem;
`;

const BottomSection = () => {
  const { t } = useTranslation('common');
  return (
    <Container className={'footer'}>
      <FooterSubtext>
        {t('footer-line-1')} {t('footer-line-2')}
      </FooterSubtext>
      <PrivacySection />
      <DesignerLogo />
    </Container>
  );
};

export default BottomSection;
