import React from 'react';
import styled from 'styled-components';
import Logo from 'assets/images/logo-full.svg';
import FooterLinks, { FooterLink } from 'desktop/components/footer/FooterLinks';
import gsap from 'gsap';
import FooterRightSection from 'desktop/components/footer/FooterRightSection';
import FollowUsSection from 'desktop/components/footer/FollowUsSection';
import { useTranslation } from 'react-i18next';
import { refHeight, refWidth } from 'desktop/utils/dimensions';
import { useScrollbar } from 'desktop/components/containers/ScrollbarProvider';
import { isTablet } from 'react-device-detect';
import BottomSection from 'desktop/components/footer/BottomSection';
import { mq } from '../../assets/styles/mediaQuery';
import { useNavigation } from '../utils/useNavigation';

const Container = styled.div`
  align-items: center;
  position: relative;
  margin-left: ${refWidth(isTablet ? 100 : 220)}vw;
  margin-right: ${refWidth(isTablet ? 100 : 200)}vw;
  padding-bottom: ${refWidth(60)}vw;
`;

const FooterWrapper = styled.div`
  margin-top: ${refHeight(100)}vh;
  display: flex;
  justify-content: space-around;
  width: 100%;

  ${mq.desktopSmall} {
    margin-top: ${refHeight(80)}vh;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 2;
`;

const StyledImg = styled.img`
  align-self: flex-start;
  width: ${refWidth(100)}vw;
  cursor: pointer;
  height: auto;
`;

const Footer = () => {
  const { t } = useTranslation('common');
  const { useScrollReady } = useScrollbar();
  const { navigate } = useNavigation();
  useScrollReady(setupAnimation);

  const sectionOneLinks: FooterLink[] = [
    { url: '/donate', description: t('footer.for-donators') },
    { url: '/shop', description: t('footer.for-shoppers') },
    { url: '/fund', description: t('footer.for-fundraisers') },
  ];

  const sectionTwoLinks: FooterLink[] = [
    { url: '/save-the-planet', description: t('save-planet') },
    { url: '/faq', description: t('faq') },
    { url: '/about', description: t('about-us') },
  ];

  return (
    <Container>
      <FooterWrapper>
        <Wrapper className={'footer'}>
          <StyledImg onClick={() => navigate('/')} src={Logo} alt={'Giveo'} />
        </Wrapper>
        <Wrapper className={'footer'}>
          <FooterLinks links={sectionOneLinks} />
        </Wrapper>
        <Wrapper className={'footer'}>
          <FooterLinks links={sectionTwoLinks} />
        </Wrapper>
        <FollowUsSection />
        <FooterRightSection />
      </FooterWrapper>
      <BottomSection />
    </Container>
  );
};

const setupAnimation = () => {
  const animation = gsap.from('.footer', {
    y: 20,
    opacity: 0,
    stagger: 0.1,
    ease: 'easeInOut',
    scrollTrigger: {
      trigger: '.footer',
      start: 'center bottom',
      end: 'bottom bottom',
      scrub: 1,
    },
  });

  return () => {
    animation.kill();
  };
};

export default Footer;
