import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isTablet } from 'react-device-detect';
import SocialMediaIcons from '../../../commons/components/commons/SocialMediaIcons';
import { refWidth } from '../../utils/dimensions';
import { FooterText } from './FooterTypography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
`;

const FollowUsSection = () => {
  const { t } = useTranslation('common');
  return (
    <Container className={'footer'}>
      <FooterText>{t('follow-us')}</FooterText>
      <IconsWrapper>
        <SocialMediaIcons iconWidth={refWidth(isTablet ? 52 : 24)} iconMarginRight={refWidth(isTablet ? 12 : 5)} />
      </IconsWrapper>
    </Container>
  );
};

export default FollowUsSection;
