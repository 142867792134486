import React from 'react';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { refFontSize, refFontSizeCalc, refWidth } from 'desktop/utils/dimensions';
import { useTranslation } from 'react-i18next';
import { mq } from 'assets/styles/mediaQuery';
import { isTablet } from 'react-device-detect';
import PointerDiv from '../commons/PointerDiv';
import { fonts } from '../../../assets/styles/fonts';
import { useNavigation } from '../../utils/useNavigation';
import { TABLET_MULTIPLIER } from '../../../commons/utils/dimensions';

const FONT_SIZE = 14 * TABLET_MULTIPLIER;

const TermsSection = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  color: ${colors.gullGray};
  font-size: ${refFontSize(FONT_SIZE)}vw;
  line-height: 2.3em;
  width: ${refWidth(isTablet ? 380 : 210)}vw;
  justify-content: space-between;
  margin-left: ${refWidth(64)}vw;

  ${mq.desktopSmall} {
    width: ${refWidth(isTablet ? 380 : 280)}vw;
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.4)}vw;
  }

  ${mq.tablet} {
  }
`;

const Terms = styled(PointerDiv)`
  color: ${colors.fadedJade};
`;

interface Props {
  onClick?: () => void;
}

const PrivacySection = ({ onClick }: Props) => {
  const { t } = useTranslation('common');
  const { navigate } = useNavigation();

  const onPrivacyClick = () => {
    navigate('/privacy-policy');
    onClick && onClick();
  };

  const onTermsClick = () => {
    navigate('/terms-of-service');
    onClick && onClick();
  };

  return (
    <TermsSection>
      <Terms onClick={onPrivacyClick}>{t('privacy-policy')}</Terms>|
      <Terms onClick={onTermsClick}>{t('terms-of-use')}</Terms>
    </TermsSection>
  );
};

export default PrivacySection;
