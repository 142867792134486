import React from 'react';
import styled from 'styled-components';
import { animations } from 'assets/styles/animations';
import { ReactComponent as DesignerIcon } from 'assets/images/heksagraf-logo.svg';
import { refWidth } from 'desktop/utils/dimensions';
import colors from 'assets/styles/colors';
import { useTranslation } from 'react-i18next';
import { mq } from '../../../assets/styles/mediaQuery';
import PointerDiv from '../commons/PointerDiv';
import { TABLET_MULTIPLIER } from '../../../commons/utils/dimensions';
import { useNavigation } from '../../utils/useNavigation';
import { FooterSubtext } from './FooterTypography';

const StyledSubtext = styled(FooterSubtext)`
  transition-duration: ${animations.standardDuration};
`;

const StyledIcon = styled(DesignerIcon)`
  width: ${refWidth(120 * TABLET_MULTIPLIER)}vw;
  margin-left: 1vw;
  height: 100%;
  fill: ${colors.gullGray};
  transition-duration: ${animations.standardDuration};

  ${mq.desktopSmall} {
    width: ${refWidth(200)}vw;
  }
`;

const Wrapper = styled(PointerDiv)`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 10px;
  margin-left: auto;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${StyledSubtext} {
      color: ${colors.red};
    }

    ${StyledIcon} {
      fill: ${colors.red};
    }
  }
`;

const DesignerLogo = () => {
  const { t } = useTranslation('common');
  const { navigate } = useNavigation();

  return (
    <Wrapper onClick={() => navigate('/')}>
      <StyledSubtext>{t('designed-by')}</StyledSubtext>
      <StyledIcon />
    </Wrapper>
  );
};

export default DesignerLogo;
