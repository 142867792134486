import React from 'react';
import styled from 'styled-components';
import { refWidth } from 'desktop/utils/dimensions';
import CreditCardSafeLight from 'assets/images/Credit_Card_Safe_light.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 5;
`;

const LinkLogo = styled.a`
  margin-left: auto;
`;

const LogoImg = styled.img`
  width: ${refWidth(130)}vw;
`;

const FooterRightSection = () => {
  return (
    <Container className={'footer'}>
      <LinkLogo
        href='//www.securitymetrics.com/site_certificate?id=1844403&tk=a1e5357225232dc9475cc8ccc92ba542'
        target='_blank'
        rel='noopener noreferrer'>
        <LogoImg src={CreditCardSafeLight} alt='SecurityMetrics Credit Card Safe' />
      </LinkLogo>
    </Container>
  );
};

export default FooterRightSection;
