import React from 'react';
import styled from 'styled-components';
import { useNavigation } from '../../utils/useNavigation';
import { PointerText } from './FooterTypography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface FooterLink {
  url: string;
  description: string;
}

interface Props {
  links: FooterLink[];
}

const FooterLinks = ({ links }: Props) => {
  const { navigate } = useNavigation();
  return (
    <Container>
      {links.map(link => (
        <PointerText key={links.indexOf(link)} onClick={() => navigate(link.url)}>
          {link.description}
        </PointerText>
      ))}
    </Container>
  );
};

export default FooterLinks;
