import React from 'react';
import styled from 'styled-components';
import CreditCardSafeLight from 'assets/images/Credit_Card_Safe_light.png';
import { refWidth } from '../../utils/dimensions';

const LogoImg = styled.img`
  width: ${refWidth(85)}vw;
`;

const CertificateSection = () => {
  return (
    <div className={'footer'}>
      <a
        href='//www.securitymetrics.com/site_certificate?id=1844403&tk=a1e5357225232dc9475cc8ccc92ba542'
        target='_blank'
        rel='noopener noreferrer'>
        <LogoImg src={CreditCardSafeLight} alt='SecurityMetrics Credit Card Safe' />
      </a>
    </div>
  );
};

export default CertificateSection;
