import React from 'react';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { useTranslation } from 'react-i18next';
import { refFontSize, refFontSizeLandscape, refWidth, refWidthLandscape } from '../../utils/dimensions';
import { fonts } from '../../../assets/styles/fonts';
import { mq } from '../../../assets/styles/mediaQuery';
import { useNavigation } from '../../utils/useNavigation';

const TermsSection = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.light};
  color: ${colors.gullGray};
  font-size: ${refFontSize(12)}vw;
  line-height: 1.5em;
  width: ${refWidth(180)}vw;
  justify-content: space-between;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(12)}vw;
    width: ${refWidthLandscape(180)}vw;
  }
`;

const Terms = styled.div`
  color: ${colors.fadedJade};
`;

interface Props {
  onClick?: () => void;
}

const PrivacySection = ({ onClick }: Props) => {
  const { t } = useTranslation('common');
  const { navigate } = useNavigation();

  const onPrivacyClick = () => {
    navigate('/privacy-policy');
    onClick && onClick();
  };

  const onTermsClick = () => {
    navigate('/terms-of-service');
    onClick && onClick();
  };

  return (
    <TermsSection>
      <Terms onClick={onPrivacyClick}>{t('privacy-policy')}</Terms>|
      <Terms onClick={onTermsClick}>{t('terms-of-use')}</Terms>
    </TermsSection>
  );
};

export default PrivacySection;
