import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { refFontSize, refFontSizeCalc, refWidth } from 'desktop/utils/dimensions';
import { fonts } from '../../../assets/styles/fonts';
import { mq } from '../../../assets/styles/mediaQuery';
import { TABLET_MULTIPLIER } from '../../../commons/utils/dimensions';

const FONT_SIZE = 14 * TABLET_MULTIPLIER;

const FooterWrapper = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  color: ${colors.gullGray};
  font-size: ${refFontSize(FONT_SIZE)}vw;
  line-height: 2em;
  width: ${refWidth(440 * TABLET_MULTIPLIER)}vw;

  ${mq.desktopSmall} {
    font-size: ${refFontSizeCalc(FONT_SIZE, 1.4)}vw;
  }
`;

const Footer = () => {
  const { t } = useTranslation('common');

  return (
    <FooterWrapper>
      {t('footer-line-1')}
      <br />
      {t('footer-line-2')}
    </FooterWrapper>
  );
};

export default Footer;
