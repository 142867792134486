import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { zIndex } from 'assets/styles/zIndex';
import { useTranslation } from 'react-i18next';
import { fonts } from 'assets/styles/fonts';
import { isTablet } from 'react-device-detect';
import { refFontSize, refFontSizeLandscape, refHeight, refHeightLandscape, refWidth } from '../../utils/dimensions';
import PhoneInputSection from '../inputs/PhoneInputSection';
import { AppStoreButton, GooglePlayButton } from '../buttons/StoreButtons';
import { mq } from '../../../assets/styles/mediaQuery';
import { useNavigation } from '../../utils/useNavigation';
import QRCodeWrapper from './QRCodeWrapper';
import GiveoCorpAddress from './GiveoCorpAddress';
import FollowUs from './FollowUs';
import PrivacySection from './PrivacySection';

const Container = styled.div<{ visible: boolean }>`
  position: absolute;
  display: flex;
  width: 100%;
  z-index: ${zIndex.belowHeader};
  background-color: ${colors.aliceBlue};
  transition: ${({ visible }) => (visible ? '1s' : '0.3s ease-in')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const Section = styled.div`
  width: ${isTablet ? 65 : 100}%;
  padding: ${refHeight(40)}vh ${refWidth(isTablet ? 80 : 25)}vw;
  z-index: ${zIndex.belowHeader};
  display: flex;
  flex-direction: column;

  ${mq.landscape} {
    padding: ${refHeight(40)}vh ${refWidth(50)}vw;
  }
`;

const MainTextSection = styled.div`
  margin-bottom: ${refHeight(10)}vh;
`;

const MainText = styled.div`
  font-family: ${fonts.family.main};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(40)}vw;
  line-height: 1em;
  color: ${colors.cello};
  margin-bottom: ${refHeight(30)}vh;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(30)}vw;
  }
`;

const Subsection = styled.div`
  margin-bottom: ${refHeight(30)}vh;

  ${mq.landscape} {
    margin-bottom: ${refHeightLandscape(30)}vw;
  }
`;

const SubSectionText = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.regular};
  font-size: ${refFontSize(22)}vw;
  line-height: 1.5em;
  color: ${colors.cello};
  margin-bottom: ${refHeight(15)}vh;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(22)}vw;
  }
`;

const SectionHeader = styled.div`
  font-family: ${fonts.family.description};
  font-weight: ${fonts.weight.medium};
  color: ${colors.fadedJade};
  font-size: ${refFontSize(16)}vw;
  line-height: 1.625em;
  margin-bottom: ${refHeight(15)}vh;

  ${mq.landscape} {
    font-size: ${refFontSizeLandscape(16)}vw;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RedirectSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${refHeight(40)}vh;
`;

interface Props {
  onSectionClick: () => void;
  visible: boolean;
}

const DownloadMenu = ({ onSectionClick, visible }: Props) => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation();

  return (
    <Container visible={visible}>
      <Section>
        <MainTextSection>
          <MainText
            onClick={() => {
              navigate('/donate');
              onSectionClick();
            }}>
            {t('burger-menu.donate')}
          </MainText>
          <MainText
            onClick={() => {
              navigate('/shop');
              onSectionClick();
            }}>
            {t('burger-menu.shop')}
          </MainText>
          <MainText
            onClick={() => {
              navigate('/fund');
              onSectionClick();
            }}>
            {t('burger-menu.fundraise')}
          </MainText>
        </MainTextSection>
        <Subsection>
          <SubSectionText
            onClick={() => {
              navigate('/save-the-planet');
              onSectionClick();
            }}
            className={'subsection-text'}>
            {t('burger-menu.save-planet')}
          </SubSectionText>
          <SubSectionText
            onClick={() => {
              navigate('/faq');
              onSectionClick();
            }}
            className={'subsection-text'}>
            {t('burger-menu.faq')}
          </SubSectionText>
          <SubSectionText
            onClick={() => {
              navigate('/about');
              onSectionClick();
            }}
            className={'subsection-text'}>
            {t('burger-menu.about-us')}
          </SubSectionText>
        </Subsection>
        <SectionHeader>{t('burger-menu.download')}</SectionHeader>
        <RedirectSection>
          <ButtonWrapper>
            <AppStoreButton />
            <GooglePlayButton />
          </ButtonWrapper>
          <QRCodeWrapper />
        </RedirectSection>
        <Subsection>
          <PhoneInputSection messageColor={colors.red} buttonColor={colors.red} />
        </Subsection>
        <Subsection>
          <FollowUs />
        </Subsection>
        <Subsection>
          <GiveoCorpAddress />
        </Subsection>
        <PrivacySection onClick={onSectionClick} />
      </Section>
    </Container>
  );
};

export default DownloadMenu;
